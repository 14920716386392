var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"pc",staticClass:"productcards"},[_c('div',{ref:"title",staticClass:"title-wrap"},[_c('div',{staticClass:"title-inner",style:(("" + (_vm.idx % 2 === 0 ? "padding-bottom: 10vw" : "")))},[_c('Title',{attrs:{"title":_vm.title,"idx":_vm.idx + 1,"show":_vm.showTitle,"minitext":_vm.minitext}})],1)]),_c('div',{ref:"rect",staticClass:"row"},_vm._l((_vm.data.items),function(item,i){return _c('transition',{key:i + _vm.key},[_c('div',{key:_vm.key,staticClass:"rect-container",style:(_vm.checkIfInTestMode(i))},[_c('a',{attrs:{"href":_vm.$cms.textField(
              _vm.data.items[_vm.data.items.length - 1 - i].external_link
            )
              ? _vm.$cms.textField(
                  _vm.data.items[_vm.data.items.length - 1 - i].external_link
                )
              : ("/" + (_vm.slugify(
                  _vm.$cms.textField(_vm.data.items[_vm.data.items.length - 1 - i].title)
                ))),"target":_vm.data.items[_vm.data.items.length - 1 - i].open_link_in_new_tab
              ? "_blank"
              : ""}},[_c('div',{ref:("rect-outer" + i),refInFor:true,staticClass:"rect-outer",style:(("margin-top: " + (40 * _vm.dir(i)) + "px"))},[_c('transition',{attrs:{"name":"rect","appear":""}},[_c('div',{ref:("rect" + i),refInFor:true,staticClass:"rect"},[(_vm.data.items[_vm.data.items.length - 1 - i].stock <= 0)?_c('div',{staticClass:"outofstock"},[_c('p',[_vm._v("Out Of Stock")])]):_vm._e(),_c('div',{staticClass:"image",style:(("background-image: url(" + (_vm.data.items[_vm.data.items.length - 1 - i].main_image.url) + ")"))})])])],1),_c('div',{staticClass:"info"},[_c('transition',{attrs:{"name":"rect","appear":""}},[_c('div',{ref:("info" + i),refInFor:true,staticClass:"info-inner"},[_c('p',[_vm._v(" "+_vm._s(_vm.$cms.textField( _vm.data.items[_vm.data.items.length - 1 - i].title ))+" ")]),_c('p',{staticClass:"price"},[(_vm.data.items[_vm.data.items.length - 1 - i].from)?_c('span',{staticClass:"small"},[_vm._v("from ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.data.items[_vm.data.items.length - 1 - i].currency.includes( "$" ) ? "$" : "")+_vm._s(_vm.$cms.textField( _vm.data.items[_vm.data.items.length - 1 - i].price ))+" "),_c('span',{staticClass:"small"},[_vm._v(_vm._s(_vm.data.items[_vm.data.items.length - 1 - i].currency.split( "$" )[1]))])])])])],1)])])])}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }